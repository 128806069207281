import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CorrectPersonalDataCheck = _resolveComponent("CorrectPersonalDataCheck")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_CorrectData = _resolveComponent("CorrectData")!
  const _component_CorrectDataAddons = _resolveComponent("CorrectDataAddons")!
  const _component_Agb = _resolveComponent("Agb")!
  const _component_ion_slides = _resolveComponent("ion-slides")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, {
    "show-back-button": true,
    customBackButtonFunc: _ctx.customBackButtonFunc,
    useCustomFunc: _ctx.useCustomFunc
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_slides, {
        ref: "slider",
        options: _ctx.slideOpts,
        class: "swiper-no-swiping"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_CorrectPersonalDataCheck, {
                onNext: _cache[0] || (_cache[0] = ($event: any) => (_ctx.next(1)))
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_CorrectData, {
                onNext: _ctx.addonsNext,
                data: _ctx.correctDataFrom,
                v: _ctx.correctDataFromValidation,
                "driving-school": _ctx.getDrivingSchool
              }, null, 8, ["onNext", "data", "v", "driving-school"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_CorrectDataAddons, {
                onNext: _cache[1] || (_cache[1] = ($event: any) => (_ctx.next(3))),
                "filtered-addons": _ctx.addonsByCurrent,
                data: _ctx.correctDataAddonsFrom,
                v: _ctx.correctDataAddonsFromValidation
              }, null, 8, ["filtered-addons", "data", "v"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_slide, null, {
            default: _withCtx(() => [
              _createVNode(_component_Agb, {
                "driving-school": _ctx.getDrivingSchool,
                "adult-user": _ctx.adultUser,
                onSubmit: _ctx.onSubmit,
                data: _ctx.form,
                v: _ctx.formValidation
              }, null, 8, ["driving-school", "adult-user", "onSubmit", "data", "v"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["options"])
    ]),
    _: 1
  }, 8, ["customBackButtonFunc", "useCustomFunc"]))
}