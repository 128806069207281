
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
import AddonsBlock from "@/views/Onboarding/TrainingContract/AddonsBlock.vue";
import GeneralTermsAndConditions from "@/views/Onboarding/TrainingContract/GeneralTermsAndConditions.vue";

export default defineComponent({
  name: "CorrectDataAddons",
  components: { GeneralTermsAndConditions, AddonsBlock },
  props: {
    data: {},
    v: {},
    filteredAddons: {},
  },
  emits: ["next"],
  setup(props, { emit }) {
    const store = useStore();

    // const studentEducationsByStudent = async (options: any) => {
    //   await store.dispatch("student-education/findAll", options);
    // };

    // const addonsByStudentEducation = async (options: any) => {
    //   await store.dispatch("price/addonsByStudentEducation/findAll", options);
    // };

    // const studentEducations = computed<Array<any>>(() => store.getters["student-education/getDataList"]);
    // const studentEducationsLoading = computed<boolean>(() => store.getters["student-education/getIsLoading"]);

    // const addonsFromStudentEducation = computed(() => store.getters["price/addonsByStudentEducation/getDataList"]);
    // const addonsFromStudentEducationLoading = computed(() => store.getters["price/addonsByStudentEducation/getIsLoading"]);

    onMounted(() => {
      // studentEducationsByStudent({
      //   resource: "student-educations/student/current",
      //   hideSuccessMessage: true,
      // });
    });

    const onNext = () => {
      //@ts-ignore
      props.v.$touch();
      //@ts-ignore
      if (props.v.$invalid) return;
      emit("next");
    };

    const onChangeTab = (index: number) => {
      // const selectedStudentEducation = studentEducations.value[index];
      // addonsByStudentEducation({
      //   resource: `/prices/addons-by-student-education/${selectedStudentEducation.id}`,
      //   hideSuccessMessage: true,
      // });
    };

    return {
      // studentEducations,
      // studentEducationsLoading,
      onNext,
      onChangeTab,
      // addonsFromStudentEducation,
      // addonsFromStudentEducationLoading,
    };
  },
});
