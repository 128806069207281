
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    addon: {
      required: true,
    },
  },
});
