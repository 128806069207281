
import { Options, Prop, Vue } from "vue-property-decorator";
import GeneralTermsAndConditions from "@/views/Onboarding/TrainingContract/GeneralTermsAndConditions.vue";

@Options({
  components: { GeneralTermsAndConditions },
})
export default class Agb extends Vue {
  public name = "Agb";

  @Prop({ required: true })
  private drivingSchool!: any;

  @Prop({ required: false, default: () => false })
  private loading!: any;

  @Prop({})
  public data!: any;

  @Prop({})
  public v!: any;

  @Prop({})
  public adultUser!: any;

  public onSubmit(): void {
    //@ts-ignore
    this.v.$touch();
    //@ts-ignore
    if (this.v.$invalid) return;
    this.$emit("submit");
  }

  public get drivingSchoolWebsite() {
    return this.drivingSchool?.website || "";
  }

  public get drivingSchoolLinks() {
    return this.drivingSchool?.drivingSchoolLink || "";
  }
}
