
import { computed, defineComponent, ref, watch } from "vue";
import groupBy from "lodash/groupBy";
import AddonBlockItem from "@/views/Onboarding/AddonBlockItem.vue";

export default defineComponent({
  name: "AddonsBlock",
  components: { AddonBlockItem },
  props: {
    studentEducations: {
      type: Array,
      default: () => [],
    },
    addons: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["on-change-tab"],
  setup(props, { emit }) {
    const tabsIndex = ref(0);

    watch(
      () => tabsIndex.value,
      () => {
        emit("on-change-tab", tabsIndex.value);
      },
      {
        immediate: true,
      }
    );

    const groupedAddons = computed(() => {
      return groupBy(props.addons, "licenseClass");
    });

    return {
      tabsIndex,
      groupedAddons,
    };
  },
});
