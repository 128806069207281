
import { defineComponent, computed, onMounted } from "vue";
import EducationPriceBlock from "@/components/Pages/Education/EducationPriceBlock.vue";
import { useStore } from "vuex";
import { IPriceByStudent } from "@/interfaces/IPriceByStudent";
import EducationBlock from "@/views/Onboarding/TrainingContract/EducationBlock.vue";
import GeneralTermsAndConditions from "@/views/Onboarding/TrainingContract/GeneralTermsAndConditions.vue";

export default defineComponent({
  name: "CorrectData",
  components: { GeneralTermsAndConditions, EducationBlock, EducationPriceBlock },
  props: {
    data: {},
    v: {},
    drivingSchool: { type: Object, required: true },
  },
  emits: ["next"],
  setup(props, { emit }) {
    const store = useStore();

    const pricesByStudent = async (options: any) => {
      await store.dispatch("price/findAll", options);
    };

    const prices = computed<Array<IPriceByStudent>>(() => store.getters["price/getDataList"]);
    const pricesLoading = computed<boolean>(() => store.getters["price/getIsLoading"]);

    const findExamAndEducation = async (options: any) => {
      await store.dispatch("student/findAll", options);
    };

    const examAndEducationList = computed<Array<IPriceByStudent>>(() => store.getters["student/getDataList"]);
    const examAndEducationListLoading = computed<boolean>(() => store.getters["student/getIsLoading"]);
    const drivingSchoolAgbLink = computed<string>(() => props.drivingSchool.termsAndConditionsUrl);

    onMounted(() => {
      pricesByStudent({
        resource: "/prices/by-student/current",
      });

      findExamAndEducation({
        resource: "students/exam-and-education/student/current",
        hideSuccessMessage: true,
      });
    });

    const onNext = () => {
      //@ts-ignore
      props.v.$touch();
      //@ts-ignore
      if (props.v.$invalid) return;
      emit("next");
    };

    return {
      prices,
      pricesLoading,
      examAndEducationList,
      examAndEducationListLoading,
      onNext,
      drivingSchoolAgbLink,
    };
  },
});
