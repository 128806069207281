
import { defineComponent, inject } from "vue";
import EducationBlock from "@/components/Pages/Education/EducationBlock.vue";
import EducationBlockBKF from "@/components/Pages/Education/EducationBlockBKF.vue";

export default defineComponent({
  name: "EducationBlock",
  components: {
    EB: EducationBlock,
    EBBKF: EducationBlockBKF,
  },
  props: {
    studentExamAndEducations: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const updateSlideHeight = inject("updateSlideHeight", () => null, false);

    const onChangeTab = () => {
      updateSlideHeight();
    };

    return {
      onChangeTab,
    };
  },
});
