import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "font-weight-bold flex-fill",
  style: {"min-width":"0"}
}
const _hoisted_2 = {
  class: "ion-text-end",
  style: {"min-width":"3.5rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_b_col, {
      cols: "7",
      class: "text-white font-size-18 form-group"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.addon.product.name), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_b_col, {
      cols: "5",
      class: "text-white font-size-18 form-group"
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.format(_ctx.addon.priceInEur)), 1)
      ]),
      _: 1
    })
  ], 64))
}