import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-size-18 font-weight-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createBlock(_component_b_overlay, {
    show: _ctx.loading,
    class: "bg-color-eminence-500 radius-10 p-4 text-white text-left"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["big-checkbox", { 'mt-3': _ctx.hasDefaultSlot }])
      }, [
        _createVNode(_component_b_form_checkbox, {
          size: "lg",
          "model-value": _ctx.modelValue,
          onInput: _ctx.onChangeModel,
          "unchecked-value": null,
          state: _ctx.invalid ? false : null
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(["checkbox-text", _ctx.checkboxTextClass])
            }, [
              _renderSlot(_ctx.$slots, "checkbox-text", {}, () => [
                _createTextVNode("Ja, ich bestätige ")
              ])
            ], 2)
          ]),
          _: 3
        }, 8, ["model-value", "onInput", "state"])
      ], 2)
    ]),
    _: 3
  }, 8, ["show"]))
}